import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ContentRectangle from '../../uielements/ContentRectangle'
import PropTypes from 'prop-types'
import InnerTitle from '../../uielements/InnerTitle'
import { useTranslation } from 'react-i18next'
import Flex from '../../uielements/Flex'
import Spacer from '../../uielements/Spacer'
import FlexWrapper from '../../uielements/flexWrapper'

const MyParentsGrid = ({ data, props }) => {
    const { t, i18n } = useTranslation()

    return (
        <>
            <InnerTitle>{t('parenting-no-tone')}</InnerTitle>
            <Spacer top="2" />
            <FlexWrapper xl={{ margin: '0 -20px 0 -10px' }}>
                {data.parents.nodes.map(page => {
                    return (
                        (props.displayInHome
                            ? page.displayInHome === 'yes'
                            : true) &&
                        page.node_locale === i18n.language && (
                            <Flex
                                flexBasis="33%"
                                key={page.id}
                                sm={{ flexBasis: '100%' }}
                                md={{ flexBasis: '50%' }}
                                lg={{ flexBasis: '50%' }}
                            >
                                <Spacer
                                    left="1"
                                    right="1"
                                    bottom="2"
                                    sm={{ right: '0', bottom: '2' }}
                                >
                                    <Link
                                        to={'/parents/' + page.slug}
                                        title={page.title}
                                    >
                                        <ContentRectangle
                                            imgObj={page.featuredImage}
                                        >
                                            {page.title}
                                        </ContentRectangle>
                                    </Link>
                                </Spacer>
                            </Flex>
                        )
                    )
                })}
            </FlexWrapper>
        </>
    )
}

const ParentsGrid = props => {
    return (
        <StaticQuery
            query={graphql`
                query fetchParents {
                    parents: allContentfulPage(
                        filter: {
                            main: { eq: "yes" }
                            postType: { eq: "parents" }
                            logotherapy: { ne: "ΝΑΙ" }
                        }
                        sort: { fields: ordering, order: ASC }
                    ) {
                        nodes {
                            slug
                            title
                            id
                            node_locale
                            main
                            createdAt
                            category {
                                slug
                                title
                                excerpt {
                                    raw
                                }
                            }
                            featuredImage {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                    cropFocus: CENTER
                                )
                            }
                        }
                        totalCount
                    }
                }
            `}
            render={data => <MyParentsGrid data={data} props={props} />}
        ></StaticQuery>
    )
}

ParentsGrid.defaultProps = {
    displayInHome: false,
}

ParentsGrid.propTypes = {
    displayInHome: PropTypes.bool,
}

export default ParentsGrid
