import React from 'react'
import Layout from '../components/layout'
import ParentsGrid from '../components/sections/ParentsGrid'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Spacer from '../components/uielements/Spacer'
import Ergotherapy from '../components/sections/Ergotherapy'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Seo from '../components/seo'

const ParentsPage = () => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('parenting-no-tone')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('parenting-no-tone')} />
                <ParentsGrid />
                <Spacer top="4" />
                <Ergotherapy />
                <Spacer top="4" />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export default ParentsPage
